import {
  CheckBoxComponent,
  SwitchComponent,
} from "@syncfusion/ej2-react-buttons";
import { PropsWithChildren } from "react";
import { Field, FieldInputProps } from "react-final-form";
import { isNotNullAndUndefined } from "../../RAFComponents/helpers/utils";
import "./InputStyle.scss";
import RAFFieldLabel from "./RAFFieldLabel";
import { RAFFieldError, composeValidators } from "./RAFForm";
import {
  RAFCheckboxFieldProps,
  RAFDefaultFieldClassName,
  RAFDefaultFieldProps,
  isRequired,
} from "./RFFUtils";

//const RAFCheckBox = ({ field, label, required }) => {
function RAFCheckBox<T>({
  field,
  label,
  onChanged,
  formGroupClassName,
  componentLabel,
  labelClassName,
  labelClass,
  children,
  labelPosition,
  unsetHeight,
  description,
  descriptionAsLabel,
  icon,
  uitype = "default",
  required = RAFDefaultFieldProps.required,
  showLabel = RAFDefaultFieldProps.showLabel,
  disabled = RAFDefaultFieldProps.disabled,
  showClearButton = RAFDefaultFieldProps.showClearButton,
  validate = RAFDefaultFieldProps.validate,
  ...props
}: PropsWithChildren<RAFCheckboxFieldProps<T>>) {
  const onClickLabelText = (
    value,
    input: FieldInputProps<any, HTMLElement>
  ) => {
    if (!disabled) {
      const fieldValue = value;
      if (uitype === "checkBoxInputNum") {
        const newValue = fieldValue === 1 ? 0 : 1;
        input.onChange(newValue);
        if (isNotNullAndUndefined(onChanged)) {
          onChanged(newValue);
        }
      } else {
        const newValue = fieldValue === true ? false : true;
        input.onChange(newValue);
        if (isNotNullAndUndefined(onChanged)) {
          onChanged(newValue);
        }
      }
    }
  };

  return (
    <div
      className={
        isNotNullAndUndefined(formGroupClassName)
          ? formGroupClassName + " form-group align-items-center py-0"
          : "form-group align-items-center py-0"
      }
    >
      <div
        className={
          props.rowClassName
            ? props.rowClassName
            : RAFDefaultFieldClassName.rowClassName
        }
        id={"rafdiv" + field.toString()}
      >
        <Field
          name={field.toString()}
          {...(props.initialValue ? { initialValue: props.initialValue } : {})}
          //validate={validate === true ? (required && isRequired) : null}
          {...(props.validators
            ? {
                validate:
                  validate === true
                    ? composeValidators(
                        required === true ? isRequired : null,
                        ...props.validators
                      )
                    : null,
              }
            : {
                validate:
                  validate === true
                    ? composeValidators(required === true ? isRequired : null)
                    : null,
              })}
          allowNull
          parse={(value) => (value === "" ? null : value)}
        >
          {({ input }) => {
            return (
              <>
                {showLabel &&
                  showLabel === true &&
                  (isNotNullAndUndefined(props.customLabel) ? (
                    props.customLabel
                  ) : (
                    <RAFFieldLabel
                      field={field}
                      label={label}
                      required={required}
                      labelClassName={`${labelClassName} ${
                        labelPosition === "right" ? "order-last" : ""
                      }`}
                      description={description}
                      descriptionAsLabel={descriptionAsLabel}
                      unsetHeight={unsetHeight}
                      labelClass={`labelClass checkbox_${
                        isNotNullAndUndefined(props.colorStyle)
                          ? props.colorStyle
                          : ""
                      }_label${input.value === true ? " active" : ""}`}
                      onClickLabelText={() =>
                        onClickLabelText(input.value, input)
                      }
                      disabled={disabled}
                    ></RAFFieldLabel>
                  ))}
                <div
                  className={
                    props.inputFieldClassName
                      ? props.inputFieldClassName
                      : "col-12"
                  }
                >
                  <div
                    className={`d-flex align-items-center w-100 checkbox_${
                      isNotNullAndUndefined(props.colorStyle)
                        ? props.colorStyle
                        : ""
                    }`}
                  >
                    {uitype === "checkIcon" ? (
                      <span className="d-flex">
                        <label
                          htmlFor={input.name}
                          className={
                            input.value === true
                              ? "complete-checkbox-btn active"
                              : "complete-checkbox-btn"
                          }
                          style={{ cursor: "pointer" }}
                        ></label>
                        <input
                          className="d-none"
                          type="checkbox"
                          id={input.name}
                          name={input.name}
                          value={input.value}
                          onChange={(e) => {
                            input.onChange(e.target.checked);
                            if (isNotNullAndUndefined(onChanged)) {
                              onChanged(e.target.checked);
                            }
                          }}
                          checked={input.value ?? false}
                          disabled={disabled}
                        />
                      </span>
                    ) : uitype === "btnToggle" ? (
                      <span>
                        <label
                          htmlFor={input.name}
                          className={
                            input.value === true
                              ? "primary-custom-button active checkbox-btn custom-button-md e-flat e-control e-btn e-lib"
                              : "e-flat custom-button-md checkbox-btn e-control e-btn e-lib"
                          }
                          style={{ cursor: "pointer" }}
                        >
                          {isNotNullAndUndefined(icon) ? (
                            <i className={`${icon} pe-2`}></i>
                          ) : (
                            ""
                          )}
                          {label}
                        </label>
                        <input
                          className="d-none"
                          type="checkbox"
                          id={input.name}
                          name={input.name}
                          value={input.value}
                          onChange={(e) => {
                            input.onChange(e.target.checked);
                            if (isNotNullAndUndefined(onChanged)) {
                              onChanged(e.target.checked);
                            }
                          }}
                          checked={input.value ?? false}
                          disabled={disabled}
                        />
                      </span>
                    ) : uitype === "pinToggle" ? (
                      <span>
                        <label
                          htmlFor={input.name}
                          className={
                            input.value === true
                              ? "e-danger e-outline checkbox-btn custom-button-sm e-flat e-control btn-min-width e-btn e-lib d-flex align-items-center"
                              : "e-flat custom-button-sm primary-custom-button checkbox-btn e-control btn-min-width e-btn e-lib d-flex align-items-center"
                          }
                          style={{ cursor: "pointer" }}
                        >
                          {isNotNullAndUndefined(icon) ? (
                            <i
                              className={`fa-sm me-1 mt-1 fas fa-thumbtack`}
                            ></i>
                          ) : (
                            ""
                          )}
                          {input.value === true ? "Pinned" : "Pin"}
                        </label>
                        <input
                          className="d-none"
                          type="checkbox"
                          id={input.name}
                          name={input.name}
                          value={input.value}
                          onChange={(e) => {
                            input.onChange(e.target.checked);
                            if (isNotNullAndUndefined(onChanged)) {
                              onChanged(e.target.checked);
                            }
                          }}
                          checked={input.value ?? false}
                          disabled={disabled}
                        />
                      </span>
                    ) : uitype === "eyeToggle" ? (
                      <span>
                        <label
                          htmlFor={input.name}
                          className={
                            input.value === true
                              ? "fas fa-eye-slash"
                              : "fas fa-eye"
                          }
                          style={{ cursor: "pointer" }}
                        ></label>
                        <input
                          style={{ visibility: "hidden" }}
                          type="checkbox"
                          id={input.name}
                          name={input.name}
                          value={input.value}
                          onChange={(e) => {
                            input.onChange(e.target.checked);
                            if (isNotNullAndUndefined(onChanged)) {
                              onChanged(e.target.checked);
                            }
                          }}
                          checked={input.value ?? false}
                          disabled={disabled}
                        />
                      </span>
                    ) : uitype === "checkCircle" ? (
                      <span>
                        <label
                          htmlFor={input.name}
                          className="fa fa-check-circle"
                          style={{
                            cursor: "pointer",
                            color: input.value === true ? "#2ecc71" : "#ddd",
                            fontSize: "20px",
                          }}
                        ></label>
                        <input
                          style={{ visibility: "hidden" }}
                          type="checkbox"
                          id={input.name}
                          name={input.name}
                          value={input.value}
                          onChange={(e) => {
                            input.onChange(e.target.checked);
                            if (isNotNullAndUndefined(onChanged)) {
                              onChanged(e.target.checked);
                            }
                          }}
                          checked={input.value ?? false}
                          disabled={disabled}
                        />
                      </span>
                    ) : uitype === "switch" ? (
                      <SwitchComponent
                        name={input.name}
                        change={(e) => {
                          input.onChange(e.checked);
                          if (isNotNullAndUndefined(onChanged)) {
                            onChanged(e.checked);
                          }
                        }}
                        disabled={disabled}
                        checked={input.value ?? false}
                      />
                    ) : uitype === "lightCheckbox" ? (
                      <CheckBoxComponent
                        name={input.name}
                        change={(e) => {
                          input.onChange(e.checked);
                          if (isNotNullAndUndefined(onChanged)) {
                            onChanged(e.checked);
                          }
                        }}
                        label={componentLabel}
                        cssClass={`e-checkbox-light${
                          isNotNullAndUndefined(props.componentCssClass)
                            ? ` ${props.componentCssClass}`
                            : ""
                        }`}
                        checked={input.value ?? false}
                        disabled={disabled}
                      />
                    ) : uitype === "checkBoxInputNum" ? (
                      <CheckBoxComponent
                        name={input.name}
                        change={(e) => {
                          const newValue = e.checked ? 1 : 0;
                          input.onChange(newValue);
                          if (isNotNullAndUndefined(onChanged)) {
                            onChanged(newValue);
                          }
                        }}
                        label={componentLabel}
                        checked={input.value === 1 ? true : false}
                        disabled={disabled}
                      />
                    ) : (
                      <CheckBoxComponent
                        name={input.name}
                        change={(e) => {
                          input.onChange(e.checked);
                          if (isNotNullAndUndefined(onChanged)) {
                            onChanged(e.checked);
                          }
                        }}
                        label={componentLabel}
                        checked={input.value ?? false}
                        disabled={disabled}
                        cssClass={props.componentCssClass}
                      />
                    )}
                    {props.hideRequiredMessage !== true ? (
                      <RAFFieldError name={field.toString()} />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </>
            );
          }}
        </Field>
      </div>
    </div>
  );
}

export default RAFCheckBox;
